<script lang="ts" setup>
import { computed } from 'vue'
import IconBed from '@lahaus-roomie/roomie/src/assets/icons/bed.svg'
import IconPin from '@lahaus-roomie/roomie/src/assets/icons/pin.svg'
import IconPrice from '@lahaus-roomie/roomie/src/assets/icons/price.svg'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import type { Props } from './types'
import { useUserStore } from '@/stores/userStore'
import './_index.scss'

import { getDeliveryTermLabel, getBedroomsLabel } from '@/utils/project'

const { t, n } = useI18n()
const props = withDefaults(defineProps<Props>(), {
  coverUrls: () => [],
  screen: 'onboarding'
})

const userStore = useUserStore()

const heroImages = computed(() => props.coverUrls.map((url: string) => ({ src: url, alt: t('cover_of', { name: props.name }) })))

const bedroomsLabel = computed(() => getBedroomsLabel(props.typologies, t))

useHead({ link: [{ rel: 'preload', href: heroImages.value[0].src + '&w=400', as: 'image' }] })
</script>

<script lang="ts">
export default {
  name: 'OnboardingHero'
}
</script>

<template>
  <a
    class="onboarding-hero cursor-pointer"
    :class="{ 'onboarding-hero--title-hidden': !name }"
    :data-lh-id="`${screen}-hero-go-to-pdp`"
    target="_blank"
    :href="path">
    <div class="onboarding-hero__background-container grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-8 pointer-events-none">
      <picture
        v-for="(imgObj, index) in heroImages.slice(0, 3)"
        :key="imgObj.src"
        :class="{
          'col-span-2 row-span-2': index == 0,
          'hidden md:block col-start-3': [1, 2].includes(index)
        }">
        <source
          media="(max-width: 480px)"
          :srcset="`${imgObj.src}&w=400`">

        <source
          v-if="index == 0"
          media="(min-width: 481px) and (max-width: 799px)"
          :srcset="`${imgObj.src}&w=480`">

        <source
          v-if="index == 0"
          media="(min-width: 800px)"
          :srcset="`${imgObj.src}&w=800`">

        <img
          class="onboarding-hero__background object-cover"
          :class="{
            'md:rounded-l-[16px]': index == 0,
            'rounded-tr-[16px]': index == 1,
            'rounded-br-[16px]': index == 2
          }"
          :loading="index == 0 ? 'eager' : 'lazy'"
          :src="imgObj.src+'&w=400'"
          :alt="imgObj.alt">
      </picture>
    </div>

    <div class="onboarding-hero__container flex items-center pointer-events-none">
      <div class="onboarding-hero__content relative px-24 py-32 md:p-60">
        <p
          class="roomie-tag text-brand-300 border border-brand-300 roomie-tag--sm mb-8 md:mb-0">
          <span>{{ getDeliveryTermLabel(props.deliveryDate, t) }}</span>
        </p>

        <div
          class="onboarding-hero__headings"
          :class="{ 'mb-24 md:mb-32': name, 'mt-4 mb-10 md:my-24': !name }">
          <h2
            v-if="name && userStore.isAuthenticated"
            class="onboarding-hero__headings__title">
            {{ name }}
          </h2>

          <p class="text-18 md:text-24 text-accent-600 md:leading-20 mt-8">
            {{ t('title') }}
          </p>
        </div>

        <div class="onboarding-hero__details">
          <p class="onboarding-hero__details__attributes mb-8">
            <IconPin class="w-24 h-24 fill-white" /> {{ neighborhood }}, {{ city }}
          </p>

          <p class="onboarding-hero__details__attributes mb-8">
            <IconBed class="w-24 h-24 fill-white" /> {{ bedroomsLabel }}
          </p>

          <p class="onboarding-hero__details__attributes">
            <IconPrice class="w-24 h-24 fill-white" /> {{ t('prices_from') }} ${{ n(minPrice) }} - {{ getDeliveryTermLabel(props.deliveryDate, t) }}
          </p>

          <RoomieButton
            :id="`${screen}-hero-cta`"
            :data-lh-id="`${screen}-hero-cta`"
            class="mt-24 md:mt-32"
            size="md">
            {{ t('cta') }}
          </RoomieButton>
        </div>
      </div>
    </div>
  </a>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
