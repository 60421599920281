import { parseBigNumberToSimplifiedCurrency } from '@/utils/number'

export const parsePriceFilterLabel = (minValue: null|number, maxValue: null|number, config: { t: any, locale: string }) => {
  const parsedMin = parseBigNumberToSimplifiedCurrency(minValue, config.locale)
  const parsedMax = parseBigNumberToSimplifiedCurrency(maxValue, config.locale)

  if (minValue && maxValue) {
    return [
      parsedMin,
      parsedMax
    ].join(' - ')
  }

  if (minValue) {
    return config.t('price.from', { value: parsedMin })
  }

  return config.t('price.to', { value: parsedMax })
}

export const parseAreaFilterLabel = (minValue: null|number, maxValue: null|number, config: { t: any, locale: string }) => {
  if (minValue && maxValue) {
    return [
      minValue + 'm²',
      maxValue + 'm²'
    ].join(' - ')
  }

  if (minValue) {
    return config.t('area.from', { value: minValue })
  }

  return config.t('area.to', { value: maxValue })
}
