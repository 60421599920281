<script lang="ts" setup>
import { ref, computed } from 'vue'

import '@lahaus-roomie/roomie/src/components/RoomieNavigationButton/_index.scss'
import FiltersIcon from '@lahaus-roomie/roomie/src/assets/icons/filter.svg'
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import RoomieChipSelector from '@lahaus-roomie/roomie/src/components/RoomieChipSelector/index.vue'
import { storeToRefs } from 'pinia'
import type { OnboardingFilters } from '../types'
import type { Props, ChipFilter } from './types'
import SearchFiltersModalContent from '@/components/SearchFilters/ModalContent/index.vue'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import AppSlider from '@/components/AppSlider/index.vue'
import './_index.scss'

import { parseAreaFilterLabel, parsePriceFilterLabel } from '@/components/OnboardingContent/FiltersBar/utils'
import { useLocationsStore } from '@/stores/locationsStore'

withDefaults(defineProps<Props>(), {
  screen: 'onboarding'
})

const { t, locale } = useI18n()

const isFiltersModalOpen = ref(false)

const locationsStore = useLocationsStore()
const searchFiltersStore = useSearchFiltersStore()
const { currentFilters: filters, isThereAnyLocationParam } = storeToRefs(searchFiltersStore)

const onModalFiltersChange = (newFilters: OnboardingFilters) => {
  searchFiltersStore.updateFilters(newFilters)
  isFiltersModalOpen.value = false
}

const removeChip = async (chip: ChipFilter) => {
  if (chip.filterName === 'businessHubs') {
    const newHubs = filters.value.businessHubs.filter(hubValue => hubValue !== chip.value)
    const neighborhoodsToDelete = locationsStore.locationsQueryUtils.getNeighborhoodsFromBusinessHubValue(chip.value).map(neighborhood => neighborhood.value)
    const newNeighborhoods = filters.value.neighborhoods.filter(neighborhoodValue => !neighborhoodsToDelete.includes(neighborhoodValue))

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      businessHubs: newHubs,
      neighborhoods: newNeighborhoods
    })
  } else if (chip.filterName === 'zonesByBusinessHub') {
    const neighborhoodsToDelete = locationsStore.locationsQueryUtils.getNeighborhoodsFromBusinessHubValue(chip.value).map(neighborhood => neighborhood.value)
    const newNeighborhoods = filters.value.neighborhoods.filter(neighborhoodValue => !neighborhoodsToDelete.includes(neighborhoodValue))
    const newHubs = filters.value.businessHubs.filter(hubValue => hubValue !== chip.value)

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      businessHubs: newHubs,
      neighborhoods: newNeighborhoods
    })
  } else if (chip.filterName === 'price') {
    await searchFiltersStore.updateFilters({
      ...filters.value,
      price: {
        min: null,
        max: null
      }
    })
  } else if (chip.filterName === 'area') {
    await searchFiltersStore.updateFilters({
      ...filters.value,
      area: {
        min: null,
        max: null
      }
    })
  } else {
    const newFilterValue = null

    await searchFiltersStore.updateFilters({
      ...filters.value,
      clusters: [],
      [chip.filterName]: newFilterValue
    })
  }
}

const chips = computed(() => {
  const totalFilters: ChipFilter[] = []

  if (filters.value.neighborhoods?.length > 0) {
    const currentHubs = locationsStore.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(filters.value.neighborhoods)

    const newChips = currentHubs.map(
      (hub) => {
        const selectedZonesByHub = locationsStore.locationsQueryUtils.getBusinessHubZonesByNeighborhoodsValues(hub.value, filters.value.neighborhoods, locale.value)

        return ({
          filterName: 'zonesByBusinessHub',
          label: t('businessHubs', { businessHub: hub.alias || hub.name, count: selectedZonesByHub.length }, selectedZonesByHub.length),
          value: hub.value
        })
      }
    )

    totalFilters.push(...newChips)
  }

  if (filters.value.businessHubs?.length > 0) {
    const selectedHubs = locationsStore.locationsQueryUtils.getBusinessHubsByValues(filters.value.businessHubs)

    const newChips = selectedHubs.map(
      hub => ({
        filterName: 'businessHubs',
        label: hub.alias || hub.name,
        value: hub.value
      })
    )

    totalFilters.push(...newChips.filter(newChip => !totalFilters.some(chip => chip.value === newChip.value)))
  }

  if (filters.value.deliveryTerm) {
    totalFilters.push({
      filterName: 'deliveryTerm',
      label: t(`delivery_terms.${filters.value.deliveryTerm}`),
      value: filters.value.deliveryTerm
    })
  }

  if (filters.value.bedroomsNumber) {
    totalFilters.push({
      filterName: 'bedroomsNumber',
      label: t('bedrooms_number', { count: filters.value.bedroomsNumber }, filters.value.bedroomsNumber as number),
      value: filters.value.bedroomsNumber
    })
  }

  if (Object.values(filters.value.price).some(Boolean)) {
    totalFilters.push({
      filterName: 'price',
      label: parsePriceFilterLabel(filters.value.price.min, filters.value.price.max, { t, locale: locale.value }),
      value: filters.value.price
    })
  }

  if (Object.values(filters.value.area).some(Boolean)) {
    totalFilters.push({
      filterName: 'area',
      label: parseAreaFilterLabel(filters.value.area.min, filters.value.area.max, { t, locale: locale.value }),
      value: filters.value.area
    })
  }

  if (filters.value.q) {
    totalFilters.push({
      filterName: 'q',
      label: t('search', { q: filters.value.q }),
      value: filters.value.q
    })
  }

  return totalFilters
})
</script>

<script lang="ts">
export default {
  name: 'OnboardingFiltersBar'
}
</script>

<template>
  <div
    class="onboarding-filters-bar"
    :class="isFiltersModalOpen ? 'z-50' : 'z-20'">
    <LazyAppModal
      v-if="isFiltersModalOpen"
      position="fixed"
      class="z-50 onboarding-filters-bar__search-filters-modal"
      @close="isFiltersModalOpen = false">
      <SearchFiltersModalContent
        :initial-filters="filters"
        :screen="`${screen}-filters-modal`"
        class="h-full"
        :enable-location-defaults="!isThereAnyLocationParam"
        enable-price-filter
        @filters-change="onModalFiltersChange" />
    </LazyAppModal>

    <div class="flex flex-col flex-1 overflow-hidden">
      <div
        v-if="chips.length"
        class="onboarding-filters-bar__chips-track"
        :class="{ 'mb-16': $slots.title }">
        <AppSlider
          :id="`${screen}-modal-filters-bar-chips`"
          class="w-full py-8"
          padding-right
          padding-left="hidden">
          <template
            v-for="chip in chips"
            :key="chip.label">
            <RoomieChipSelector
              :id="`${screen}-filters-bar-item-${chip.filterName}`"
              :data-lh-id="`${screen}-filters-bar-item-${chip.filterName}`"
              icon-placement="right"
              :is-selected="false"
              class="onboarding-filters-bar__chip"
              @click="() => removeChip(chip)">
              <template #content>
                <span class="text-12 md:text-14 whitespace-nowrap pointer-events-none">
                  {{ chip.label }}
                </span>
              </template>

              <template #icon>
                <CloseIcon class="w-24 h-24 fill-current fill-primary-600 pointer-events-none" />
              </template>
            </RoomieChipSelector>
          </template>
        </AppSlider>
      </div>

      <div
        v-else-if="placeholder"
        class="pl-24 md:pl-0 my-auto lg:hidden">
        <p class="onboarding-filters-bar__placeholder-text text-brand-800 text-12 font-semibold">
          {{ placeholder }}
        </p>
      </div>

      <div
        v-if="$slots.title"
        class="onboarding-filters-bar__title flex-1">
        <slot name="title" />
      </div>
    </div>

    <div
      class="onboarding-filters-bar__modal-preferences-trigger flex gap-8"
      :class="{'items-start': chips.length, 'items-center': !chips.length}">
      <button
        :id="`${screen}-filters-preferences-trigger`"
        :data-lh-id="`${screen}-filters-preferences-trigger`"
        class="roomie-button-navigation roomie-button-navigation--light px-10 lg:px-16 lg:pl-24 py-10 lg:py-12 rounded-circle lg:rounded-md border border-primary-600"
        :aria-label="t('filters_modal_trigger.aria_label')"
        @click="isFiltersModalOpen = true">
        <p class="text-14 text-primary-600 font-medium hidden lg:block mr-12">
          {{ t('editPreferences') }}
        </p>

        <FiltersIcon class="w-18 h-18 lg:w-24 lg:h-24 fill-current fill-primary-600 pointer-events-none" />
      </button>
    </div>
  </div>
</template>

<i18n src="./i18n.json" />
