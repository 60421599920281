<script lang="ts" setup>
import type { Props } from './types'

defineProps<Props>()
</script>

<script lang="ts">
export default {
  name: 'AppEmptyState'
}
</script>

<template>
  <div class="app-empty-state p-16 mx-auto border border-carbon-light-300 rounded-8 flex flex-col md:items-center md:flex-row gap-14">
    <slot name="icon" />

    <div class="flex-1">
      <p
        v-if="title"
        class="mb-4 text-18 md:text-20 font-medium text-carbon-800">
        {{ title }}
      </p>

      <p
        v-if="description"
        class="mb-16 md:mb-0 text-carbon-600 text-14 leading-10">
        {{ description }}
      </p>
    </div>

    <div>
      <slot />
    </div>
  </div>
</template>
