<script lang="ts" setup>
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'

import type { Props } from './types'
import { LazyAppModal } from '#components'
import AppEmptyState from '@/components/App/EmptyState/index.vue'
import AppStatefulContactForm from '@/components/App/StatefulContactForm/index.vue'
import type { ContactFormData } from '@/App/StatefulContactForm/types'
import { SCREEN, SCREEN_CTA, TYPE_ATTENTION } from '@/utils/segment'
import { useSearchFiltersStore } from '@/stores/searchFiltersStore'
import { useLocationsStore } from '@/stores/locationsStore'

import './_index.scss'

const props = withDefaults(defineProps<Props>(), {
  screen: SCREEN.INVESTMENT_QUIZ
})

const { t, locale } = useI18n()
const { $trackLead } = useNuxtApp()

const isContactModalVisible = ref(false)

const toggleContactModal = () => {
  isContactModalVisible.value = !isContactModalVisible.value
}

const opportunityTypeEventMap: Record<string, string> = {
  living: 'LIVING',
  investing: 'INVESTMENT'
}

const onboardingOpportunityType = useCookie<string>('onboarding_opportunity_type')
const onboardingBedroomsNumber = useCookie<number>('onboarding_bedrooms_number')

const locationsStore = useLocationsStore()
const searchFiltersStore = useSearchFiltersStore()
const { currentFilters } = storeToRefs(searchFiltersStore)

const currentBusinessHubsCodes = computed(() => {
  const businessHubs = locationsStore.locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(currentFilters.value.neighborhoods)

  return businessHubs.map(({ code }) => code)
})

const getLeadProperties = (form) => {
  const data = {
    screen: props.screen,
    screen_cta: SCREEN_CTA.INVESTMENT_LET_ME_KNOW_BUTTON,
    phone: form.phone,
    phone_number: form.phoneNumber,
    phone_prefix: form.phonePrefix,
    type_attention: TYPE_ATTENTION.COMMERCIAL_OPERATIONS,
    business_hub_code: currentBusinessHubsCodes.value.length ? currentBusinessHubsCodes.value[0] : undefined,
    location_of_interest_codes: [locale.value.toUpperCase()],
    purchase_purpose: onboardingOpportunityType.value && opportunityTypeEventMap[onboardingOpportunityType.value] ? opportunityTypeEventMap[onboardingOpportunityType.value] : 'INVESTMENT_AND_LIVING'
  }

  if (onboardingBedroomsNumber.value) {
    data.bedrooms = onboardingBedroomsNumber.value
  }

  return data
}

const submitHandler = async (form: ContactFormData) => {
  const leadPayload = getLeadProperties(form)

  await $trackLead({
    phone: leadPayload.phone,
    screen: leadPayload.screen,
    screenCta: leadPayload.screen_cta,
    listings: [],
    properties: leadPayload
  })

  // @ts-ignore
  window.dataLayer?.push({
    event: 'lead-creation-attempted',
    screen: leadPayload.screen,
    screen_cta: leadPayload.screen_cta
  })

  // @ts-ignore
  window.dataLayer?.push({
    event: 'lead-creation-requested',
    phone: leadPayload.phone,
    screen: leadPayload.screen,
    screen_cta: leadPayload.screen_cta
  })
}
</script>

<script lang="ts">
export default {
  name: 'OnboardingEmptyResultsBanner'
}
</script>

<template>
  <div class="px-24 lg:px-40">
    <LazyAppModal
      v-if="isContactModalVisible"
      class="onboarding-contact-modal"
      @close="toggleContactModal">
      <AppStatefulContactForm
        id="onboarding-contact-modal-form"
        class="onboarding-contact-modal-form flex-1 pt-40"
        :loading-text="t('contactModal.loadingText')"
        :success-text="t('contactModal.successText')"
        :submit-button-text="t('contactModal.ctaText')"
        :submit-handler
        :reset-after="5000"
        :screen="screen"
        :screen-cta="SCREEN_CTA.INVESTMENT_LET_ME_KNOW_BUTTON"
        @reset="toggleContactModal">
        <template #above-form>
          <img
            src="https://lahaus-roomie.imgix.net/roomie/pictograms/alert.svg"
            class="w-72 h-72 mb-8"
            alt="alert">

          <div class="flex items-center mb-28">
            <h3
              v-sanitize="t('contactModal.title')"
              class="app-contact-form__title text-carbon-800 text-18 font-medium" />
          </div>
        </template>
      </AppStatefulContactForm>
    </LazyAppModal>

    <AppEmptyState
      id="onboarding-empty-results-banner"
      :title="t('title')"
      :description="t('description')"
      icon="search"
      class="my-24 w-full">
      <template #icon>
        <img
          src="https://lahaus-roomie.imgix.net/roomie/pictograms/alert.svg"
          class="w-48 h-48 md:w-50 md:h-50"
          :alt="t('ctaText')">
      </template>

      <RoomieButton
        id="onboarding-empty-results-banner-cta"
        data-lh-id="onboarding-empty-results-banner-cta-clicked"
        class="onboarding-empty-results-banner__cta w-full md:w-auto0 py-8"
        type="button"
        size="sm"
        @click="toggleContactModal">
        {{ t('ctaText') }}
      </RoomieButton>
    </AppEmptyState>
  </div>
</template>

<i18n src="./i18n.json" />
