import { Filter } from '@/composables/useOnboarding/types'
import { InvestmentProjectSummary } from '@/server/api/buyer-front/types'

export const isTruthyFilter = (filter: Filter) => {
  if (Array.isArray(filter.value)) return filter.value.length > 0
  else return ![undefined, null, ''].includes(filter.value as any)
}

export const convertFiltersArrayToObject = (filters: Filter[]) => {
  const formattedFilters = {}
  filters.forEach(filter => (formattedFilters[filter.key] = filter.value))

  return formattedFilters
}

export const extractListingIdsFromProjectsSummaries = (projectsSummaries: InvestmentProjectSummary[]) => {
  return projectsSummaries.filter(project => !!project?.code).map(project => ({ id: project.code }))
}
