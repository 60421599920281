<script lang="ts" setup>
import { ref, watch, toRaw, useSlots } from 'vue'

import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomiePhoneInput from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import IconSuccess from '@lahaus-roomie/roomie/src/assets/icons/success.svg'
import IconWarning from '@lahaus-roomie/roomie/src/assets/icons/warning.svg'
import type { Props } from './types'
import LegalPolicies from '@/components/LegalPolicies/index.vue'

import { useContactFormStore } from '@/stores/contactFormStore'

const props = withDefaults(defineProps<Props>(), {
  resetAfter: null
})
const emit = defineEmits(['submit', 'loading', 'success', 'error', 'reset'])
const slots = useSlots()
const logger = useLogger()

const contactFormStore = useContactFormStore()

const { t, locale } = useI18n()

const legalPolicyAccepted = ref(false)
const showWarningLegalPolicy = ref(false)

const form = ref({
  phone: contactFormStore.userPhone,
  phoneNumber: '',
  phonePrefix: '',
  isValid: false
})

const isLoading = ref(false)
const isSuccess = ref(false)
const hasError = ref(false)

watch(() => contactFormStore.userPhone, () => {
  form.value.phone = contactFormStore.userPhone
})

watch(isSuccess, (newValue) => {
  if (props.resetAfter === null) return

  if (newValue) {
    setTimeout(() => {
      isSuccess.value = false
      emit('reset')
    }, props.resetAfter)
  }
})

const setPhoneProperties = (value: PhoneProperties) => {
  form.value.phoneNumber = value.phoneNumber
  form.value.phonePrefix = value.code
}

const onSubmit = async () => {
  if (!form.value.isValid) return

  if (!legalPolicyAccepted.value) {
    showWarningLegalPolicy.value = true
    return
  }

  showWarningLegalPolicy.value = false

  emit('submit')

  try {
    hasError.value = false
    isLoading.value = true
    emit('loading')
    await props.submitHandler(toRaw(form.value))
    contactFormStore.updateUserPhone(form.value.phone)

    isLoading.value = false
    isSuccess.value = true
    hasError.value = false
    emit('success')
  } catch (err) {
    logger.error(err)
    hasError.value = true
    emit('error')
  }
}
</script>

<script lang="ts">
export default {
  name: 'AppStatefulContactForm'
}
</script>

<template>
  <div class="stateful-contact-form">
    <div
      v-if="isLoading"
      class="stateful-contact-form-loading">
      <slot
        v-if="slots['loading-content']"
        name="loading-content" />

      <div
        v-else
        class="pt-24 pb-48 w-full flex flex-col items-center justify-center text-center">
        <RoomieLoader
          class="m-auto mb-16"
          size="lg" />

        <p
          v-sanitize="loadingText"
          class="stateful-contact-form-loading-text font-medium leading-9 inline-block text-center text-18" />
      </div>
    </div>

    <div
      v-else-if="hasError"
      class="stateful-contact-form-error-container self-start">
      <slot
        v-if="slots['error-content']"
        name="error-content" />

      <div
        v-else
        class="pb-24 px-24 w-full flex flex-col items-center text-center">
        <span class="stateful-contact-form-error stateful-contact-form-error-icon">
          <IconWarning class="fill-warning-600 w-52 h-52 mb-16" />
        </span>

        <p
          v-sanitize="t('defaultErrorText')"
          class="stateful-contact-form-error-message text-18 font-medium leading-9" />
      </div>
    </div>

    <div
      v-else-if="isSuccess"
      class="stateful-contact-form-success-container self-start">
      <slot
        v-if="slots['success-content']"
        name="success-content" />

      <div
        v-else
        class="pb-24 px-24 w-full flex flex-col items-center text-center">
        <span class="stateful-contact-form-success stateful-contact-form-success-icon">
          <IconSuccess class="fill-primary-600 w-52 h-52 mb-16" />
        </span>

        <p
          v-sanitize="successText"
          class="stateful-contact-form-success-message text-18 font-medium leading-9" />
      </div>
    </div>

    <div v-else>
      <slot name="above-form" />

      <form
        @submit.prevent="onSubmit">
        <RoomiePhoneInput
          :id="`${id}-phone-input`"
          :data-lh-id="`${id}-phone-input`"
          name="phone"
          class="mb-16"
          :initial-country="locale"
          :number-label="t('form.numberLabel')"
          :country-label="t('form.countryLabel')"
          is-required
          :value="form.phone"
          @full="(value: PhoneProperties) => setPhoneProperties(value)"
          @input="(newValue: string) => form.phone = newValue"
          @is-valid="(isValid: Event) => form.isValid = isValid" />

        <LegalPolicies
          v-model="legalPolicyAccepted"
          :phone="form.phone"
          :show-warning="showWarningLegalPolicy"
          :screen
          :screen-cta="screenCta" />

        <RoomieButton
          :id="`${id}-send-button`"
          :data-lh-id="`${id}-send-button`"
          full-width
          type="submit">
          <span
            class="flex items-center">
            {{ submitButtonText }}
          </span>
        </RoomieButton>
      </form>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json" />
