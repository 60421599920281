import { slugify } from '@/utils/strings'
import { InvestmentProjectSummary, Typology } from '@/server/api/buyer-front/types'
import type { SearchFilters } from '@/components/SearchFilters/types'
import type { LocationsQueryUtils } from '@/stores/locationsStore/types'

export const typologyBedroomsFilter = (selectedValue: SearchFilters['bedroomsNumber'], typology: Typology) => {
  if (selectedValue === null) return true

  return typology.bedroom === selectedValue
}

export const bedroomsFilter = (selectedValue: number, project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyBedroomsFilter(selectedValue, typology))
  if (opposite) return !matches
  return matches
}

export const typologyPriceFilter = (selectedValue: SearchFilters['price'], typology: Typology) => {
  if (selectedValue.min === null && selectedValue.max === null) return true
  if (selectedValue.min === null && selectedValue.max !== null) return typology.price <= selectedValue.max
  if (selectedValue.max === null && selectedValue.min !== null) return typology.price >= selectedValue.min

  return typology.price >= selectedValue.min && typology.price <= selectedValue.max
}

export const typologyAreaFilter = (selectedValue: SearchFilters['area'], typology: Typology) => {
  if (selectedValue.min === null && selectedValue.max === null) return true
  if (selectedValue.min === null && selectedValue.max !== null) return typology.min_area <= selectedValue.max
  if (selectedValue.max === null && selectedValue.min !== null) return typology.min_area >= selectedValue.min

  return typology.min_area >= selectedValue.min && typology.min_area <= selectedValue.max
}

export const priceFilter = (selectedValue: SearchFilters['price'], project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyPriceFilter(selectedValue, typology))

  if (opposite) return !matches
  return matches
}

export const areaFilter = (selectedValue: { min: number; max: number }, project: InvestmentProjectSummary, opposite = false) => {
  if (!project.typologies) return false

  const matches = project.typologies.some(typology => typologyAreaFilter(selectedValue, typology))

  if (opposite) return !matches
  return matches
}

export const searchFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = project.name.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.description.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.neighborhood_label.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.zone_label.toLowerCase().includes(selectedValue.toLowerCase()) ||
    project.city_label.toLowerCase().includes(selectedValue.toLowerCase())

  if (opposite) return !matches
  return matches
}

export const idsFilter = (selectedValue: string[], project: InvestmentProjectSummary, opposite = false) => {
  const matches = selectedValue.includes(project.code)
  if (opposite) return !matches
  return matches
}

export const deliveryTermFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const currentDate = new Date(Date.now())
  currentDate.setHours(0, 0, 0, 0)

  const projectDeliveryDate = new Date(project.delivered_at)

  let matches: boolean

  if (selectedValue === 'immediate') {
    const maxLimitDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3))
    matches = projectDeliveryDate <= maxLimitDate
  }

  if (selectedValue === '3-to-6-months') {
    const minLimitDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3))
    const maxLimitDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6))
    matches = projectDeliveryDate > minLimitDate && projectDeliveryDate <= maxLimitDate
  }

  if (selectedValue === 'more-than-6-months') {
    const minLimitDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6))
    matches = projectDeliveryDate > minLimitDate
  }

  if (opposite) return !matches

  return matches
}

export const neighborhoodsFilter = (selectedValues: string[], project: InvestmentProjectSummary, opposite: boolean, locationsQueryUtils: LocationsQueryUtils) => {
  const businessHubsNames = locationsQueryUtils.getBusinessHubsByNeighborhoodsValues(selectedValues).map(hub => hub.name)
  const neighborhoodNames = locationsQueryUtils.getNeighborhoodsByValues(selectedValues).map(neighborhood => neighborhood.name)

  const matches = neighborhoodNames.includes(project.neighborhood_label) && businessHubsNames.includes(project.business_hub)

  if (opposite) return !matches
  return matches
}

export const citySlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.city_label) === selectedValue
  if (opposite) return !matches
  return matches
}

export const zoneSlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.zone_label) === selectedValue
  if (opposite) return !matches
  return matches
}

export const neighborhoodSlugFilter = (selectedValue: string, project: InvestmentProjectSummary, opposite = false) => {
  const matches = slugify(project.neighborhood_label) === selectedValue

  if (opposite) return !matches

  return matches
}
