<script lang="ts" setup>
import type { Props } from './types'
import './_index.scss'
import AppSlider from '@/components/AppSlider/index.vue'
import { SCREEN } from '@/utils/segment'

withDefaults(defineProps<Props>(), {
  screen: SCREEN.INVESTMENT_QUIZ
})

const { t } = useI18n()
const route = useRoute()

const getExactBedrooms = (carrouselKey = '') => {
  if (carrouselKey === 'bedrooms_number') return null

  return route.query.bedrooms_number ? parseInt(route.query.bedrooms_number as string) : null
}
</script>

<script lang="ts">
export default {
  name: 'OnboardingResultsCarousels'
}
</script>

<template>
  <div class="onboarding-results-carousel">
    <div v-if="exactMatchProjects?.length">
      <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
        {{ t("exactResultsTitle") }}
        <span>
          ({{ t('projectsNumber', { n: exactMatchProjects?.length }) }})
        </span>
      </h2>

      <div
        v-if="isLoading"
        class="h-440" />

      <AppSlider
        v-if="!isLoading"
        id="onboarding-exact-match-results-carousel"
        lateral-paddings="hidden"
        shadow-height="440px"
        :slider-scroll="400"
        show-buttons>
        <InvestmentProjectCard
          v-for="(project, index) in exactMatchProjects"
          :key="project.code"
          :project="project"
          :site="`${screen}-exact-match-${index}`"
          :exact-bedrooms="(getExactBedrooms() as number)"
          picture-lazy-load
          clickable />
      </AppSlider>
    </div>

    <template
      v-for="(carousel, index) in extendedSearch"
      :key="index">
      <div v-if="carousel.projects.length">
        <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
          {{ t(`extendedSearch.${carousel.filter}`) }}
          <span>
            ({{ t('projectsNumber', { n: carousel.projects.length }) }})
          </span>
        </h2>

        <div
          v-if="isLoading"
          class="h-440" />

        <AppSlider
          v-if="!isLoading"
          :id="`onboarding-extended-search-${carousel.filter}-results-carousel`"
          lateral-paddings="hidden"
          shadow-height="440px"
          :slider-scroll="400"
          show-buttons>
          <InvestmentProjectCard
            v-for="(project, i) in carousel.projects"
            :key="project.code"
            :project="project"
            :site="`${screen}-${carousel.filter}-${i}`"
            :exact-bedrooms="(getExactBedrooms(carousel.filter) as number)"
            picture-lazy-load
            clickable />
        </AppSlider>
      </div>
    </template>

    <template
      v-for="(carousel, index) in extraResults"
      :key="index">
      <div v-if="carousel.projects.length">
        <h2 class="text-16 lg:text-24 text-carbon-800 font-semibold mb-14">
          {{ t(`extraResults.${carousel.filter}`) }}
          <span>
            ({{ t('projectsNumber', { n: carousel.projects.length }) }})
          </span>
        </h2>

        <div
          v-if="isLoading"
          class="h-440" />

        <AppSlider
          v-if="!isLoading"
          :id="`onboarding-${carousel.filter}-extra-results-carousel`"
          lateral-paddings="hidden"
          shadow-height="440px"
          :slider-scroll="400"
          show-buttons>
          <InvestmentProjectCard
            v-for="(project, elIndex) in carousel.projects"
            :key="project.code"
            :project="project"
            :site="`${screen}-extra-results-${elIndex}`"
            picture-lazy-load
            clickable />
        </AppSlider>
      </div>
    </template>
  </div>
</template>

<i18n src="./i18n.json" />
